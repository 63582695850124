import stripeService from '../services/stripeService';

export const functions = {
  data() {
    return {
      SECRET_KEY_CRYPTO: 'ASD156QWE123DAS1#',
    };
  },
  methods: {
    /**
     *
     * @param {string} key
     * @param {object} obj
     */
    ls_add(key, obj) {
      localStorage.setItem(key, obj);
    },
    /**
     *
     * @param {string} key
     */
    ls_get(key) {
      return JSON.parse(localStorage.getItem(key));
    },
    ls_clear() {
      localStorage.clear();
    },
    ls_remove(key) {
      localStorage.removeItem(key);
    },
    validateCNPJ(cnpj) {
      // Aceita receber o valor como string, número ou array com todos os dígitos
      const validTypes =
        typeof cnpj === 'string' ||
        Number.isInteger(cnpj) ||
        Array.isArray(cnpj);

      // Elimina valor em formato inválido
      if (!validTypes) return false;

      // Guarda um array com todos os dígitos do valor
      const numbers = cnpj.toString().match(/\d/g).map(Number);

      // Valida a quantidade de dígitos
      if (numbers.length !== 14) return false;

      // Elimina inválidos com todos os dígitos iguais
      const items = [...new Set(numbers)];
      if (items.length === 1) return false;

      // Cálculo validador
      const calc = (x) => {
        const slice = numbers.slice(0, x);
        let factor = x - 7;
        let sum = 0;

        for (let i = x; i >= 1; i--) {
          const n = slice[x - i];
          sum += n * factor--;
          if (factor < 2) factor = 9;
        }

        const result = 11 - (sum % 11);

        return result > 9 ? 0 : result;
      };

      // Separa os 2 últimos dígitos de verificadores
      const digits = numbers.slice(12);

      // Valida 1o. dígito verificador
      const digit0 = calc(12);
      if (digit0 !== digits[0]) return false;

      // Valida 2o. dígito verificador
      const digit1 = calc(13);
      return digit1 === digits[1];
    },
    formatCNPJ(cnpj) {
      return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '$1.$2.$3/$4-');
    },
    formatCEP(cep) {
      return cep.replace(/(\d{2})(\d{3})/, '$1.$2-');
    },
    formatDate(date) {
      let data = new Date(date);

      let day = String(data.getDate()).padStart(2, '0');
      let month = String(data.getMonth() + 1).padStart(2, '0');
      let year = data.getFullYear();

      return `${day}/${month}/${year}`;
    },
    serializedNameDocument(nm_document) {
      return nm_document
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\s/g, '_')
        .toLowerCase();
    },
    handleNavigateCheckout(router, { plan_id, customer_email } = {}) {
      const url = document.URL;

      if (url.includes('compliance')) {
        stripeService.redirectToCheckoutCompliance();
      } else {
        stripeService.redirectToCheckoutEasy(plan_id, customer_email);
      }
    },
    handleEncoderParam(param) {
      const encryptedText = btoa(param);

      return encryptedText;
    },
    handleDecoderParam(param) {
      const decryptedText = atob(param);

      return decryptedText;
    },
  },
};
